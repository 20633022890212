<template>
  <div class="slider">
    <div class="arrows">
      <b-icon
        @click="$refs.myVueperSlides.previous()"
        icon="arrow-left"
        font-scale="2"
        style="color: #75cad5; cursor: pointer"
      ></b-icon>
    </div>
    <vueper-slides
      ref="myVueperSlides"
      autoplay
      :touchable="false"
      :duration="12000"
      fade
      :bullets="false"
      :arrows="false"
      class="no-shadow brand_partners"
      :visible-slides="4"
      fixed-height="150px"
      slide-multiple
      :gap="3"
      :breakpoints="{
        1200: {visibleSlides: 3, slideMultiple: 3},
        800: {visibleSlides: 2, slideMultiple: 2},
      }"
    >
      <vueper-slide
        v-for="(slide, i) in slides"
        :key="i"
        style="background-color: transparent"
      >
        <template v-slot:content>
          <div class="vueperslide__content-wrapper" style="flex-direction: row">
            <div v-if="slide.link !== ''">
              <a :href="slide.link" target="_blank" rel="noopener noreferrer">
                <img class="brand_logo clickeable" :src="slide.image" />
              </a>
            </div>
            <div v-else>
              <img class="brand_logo" :src="slide.image" />
            </div>
          </div>
        </template>
      </vueper-slide>
    </vueper-slides>
    <div class="arrows">
      <b-icon
        @click="$refs.myVueperSlides.next()"
        icon="arrow-right"
        font-scale="2"
        style="color: #75cad5; cursor: pointer"
      ></b-icon>
    </div>
  </div>
</template>

<script>
import {VueperSlides, VueperSlide} from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  components: {VueperSlides, VueperSlide},
  data: () => ({
    slides: [
      {
        image: require("../assets/companies-logo/Gimbalcube_logo.png"),
        link: "https://www.gimbalcube.com",
      },
      {
        image: require("../assets/companies-logo/nissan-2020-seeklogo.com.png"),
        link: "",
      },
      {image: require("../assets/companies-logo/ibm.png"), link: ""},
      {image: require("../assets/companies-logo/ito_en_logo.png"), link: ""},
      {image: require("../assets/companies-logo/sk-ii.png"), link: ""},
      {image: require("../assets/companies-logo/salesforce-2.png"), link: ""},
      {image: require("../assets/companies-logo/Philips_logo.png"), link: ""},
      {
        image: require("../assets/companies-logo/moonmode_logo.png"),
        link: "https://www.moonmode.com/",
      },
      {
        image: require("../assets/companies-logo/OHS.png"),
        link: "https://www.ohs.ac.jp",
      },
      {
        image: require("../assets/companies-logo/Mission_One_logo.png"),
        link: "https://www.mission-one.jp/en",
      },
      {
        image: require("../assets/companies-logo/Unico_logo.png"),
        link: "https://unico-inc.jp",
      },
      {
        image: require("../assets/companies-logo/Kirinzi_logo.png"),
        link: "http://kirinzi.jp",
      },
      {
        image: require("../assets/companies-logo/westpierlogo.png"),
        link: "https://www.westpierstudio.com",
      },
      {
        image: require("../assets/companies-logo/tribered_header_dark_s.png"),
        link: "http://tribe.red",
      },
    ],
  }),
};
</script>
<style scoped>
.slider {
  margin: auto;
  padding: 20px;
  height: 190px;
  width: 70%;
  background-color: whitesmoke;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
.brand_partners {
  width: 90%;
}
.clickeable {
  cursor: pointer;
}

.brand_logo {
  padding: 15px;
  max-width: 200px;
  max-height: 170px;
  height: max-content;
}

@media only screen and (max-width: 996px) {
  .slider {
    width: 90%;
  }
}

@media only screen and (max-width: 570px) {
  .arrows {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .arrows {
    display: none;
  }
  .brand_logo {
    padding: 0px;
    width: 100px;
  }
}
</style>
