<template>
  <b-card tag="article" style="border: none" class="card_shadow card">
    <b-card-img :src="image" alt="Image" top />
    <b-card-text>
      <h3>
        <strong>{{ name }}</strong>
      </h3>
      <p v-for="desc in description" :key="desc">
        <strong>{{ desc }}</strong>
      </p>
      <div v-if="techno.length != 0">
        <hr />
        <p v-for="tech in techno" :key="tech">
          <strong>{{ tech }}</strong>
        </p>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  props: ["name", "description", "techno", "image"],
};
</script>

<style lang="scss" scoped>
.card {
  text-align: center;
}
h3 {
  text-align: center;
  margin: 30px 1px;
  font-family: "Poppins", sans-serif;
  color: #d67511;
  text-transform: uppercase;
}
p {
  margin: 5px 0;
  color: #3c1c35;
  font-size: 15px;
}
hr {
  margin-top: 15px;
  background-color: #d67511;
  height: 2px;
  border-radius: 2px;
}
.card_shadow {
  background-color: transparent;
  margin: 0 auto;
}
</style>
