<template>
  <div class="services">
    <neko-separator :separator_text="$t('WHAT_WE_DO')" />
    <b-container id="text">
      <p>
        {{ $t("SERVICES_PARAGRAPH_1") }}
      </p>
      <p>
        {{ $t("SERVICES_PARAGRAPH_2") }}
      </p>
      <p>
        {{ $t("SERVICES_PARAGRAPH_3") }}
      </p>
      <p>
        {{ $t("SERVICES_PARAGRAPH_4") }}
      </p>
      <div class="white_bg card-shadow">
        <h1 class="offer_title">{{ $t("WHAT_WE_OFFER") }}</h1>
        <ul>
          <li>
            <div class="icon_image">
              <img
                class="neko_icon_services"
                :src="require('../assets/icons-services/Full_Stack.svg')"
              />
            </div>
            <div>
              <p class="service_title">
                {{ $t("SOFTWARE_DEVELOPMENT_AND_CONSULTING") }}
              </p>
              <p>{{ $t("SOFTWARE_DEVELOPMENT_AND_CONSULTING_TEXT") }}</p>
            </div>
          </li>
          <li>
            <div class="icon_image">
              <img
                class="neko_icon_services"
                :src="require('../assets/icons-services/R&D.svg')"
              />
            </div>
            <div>
              <p class="service_title">
                {{ $t("RESEARCH_AND_DEVELOPMENT") }}
              </p>
              <p>{{ $t("RESEARCH_AND_DEVELOPMENT_TEXT") }}</p>
            </div>
          </li>
          <li>
            <div class="icon_image">
              <img
                class="neko_icon_services"
                :src="require('../assets/icons-services/Installation.svg')"
              />
            </div>
            <div>
              <p class="service_title">
                {{ $t("INSTALLATIONS") }}
              </p>
              <p>{{ $t("INSTALLATIONS_TEXT") }}</p>
            </div>
          </li>
          <li>
            <div class="icon_image">
              <img
                class="neko_icon_services"
                :src="require('../assets/icons-services/UI_Design.svg')"
              />
            </div>
            <div>
              <p class="service_title">
                {{ $t("DESIGN_WEB_MOBILE") }}
              </p>
              <p>{{ $t("DESIGN_WEB_MOBILE_TEXT") }}</p>
            </div>
          </li>

          <li>
            <div class="icon_image">
              <img
                class="neko_icon_services"
                :src="require('../assets/icons-services/Game.svg')"
              />
            </div>
            <div>
              <p class="service_title">
                {{ $t("GAME_DESIGN") }}
              </p>
              <p>{{ $t("GAME_DESIGN_TEXT") }}</p>
            </div>
          </li>

          <li>
            <div class="icon_image">
              <img
                class="neko_icon_services"
                :src="require('../assets/icons-services/Creative_Contents.svg')"
              />
            </div>
            <div>
              <p class="service_title">
                {{ $t("ASSET_AND_CONTENTS_CREATION") }}
              </p>
              <p>{{ $t("ASSET_AND_CONTENTS_CREATION_TEXT") }}</p>
            </div>
          </li>
          <li>
            <div class="icon_image">
              <img
                class="neko_icon_services"
                :src="require('../assets/icons-services/Consultation.svg')"
              />
            </div>
            <div>
              <p class="service_title">
                {{ $t("TRANSLATION_AND_PROOFREADING") }}
              </p>
              <p>{{ $t("TRANSLATION_AND_PROOFREADING_TEXT") }}</p>
            </div>
          </li>
        </ul>
      </div>
    </b-container>
    <div class="spacer" />
    <neko-separator :separator_text="$t('PLATFORMS_TECHNOLOGY')" />

    <b-container>
      <div class="my-grid-platforms">
        <techno-card
          :image="require('../assets/webFE.png')"
          :name="'Web'"
          :description="['CMSs', 'APIs ', 'BaaS', 'Blockchain']"
          :techno="[
            'Javascript',
            'Coffeescript',
            'AngularJS',
            'BackboneJS',
            'jQuery',
            'Ajax',
            'WebGL',
            'HTML5 / CSS',
            'React',
            'VueJS',
          ]"
        />
        <techno-card
          :image="require('../assets/backend.png')"
          :name="'BACKEND'"
          :description="[
            'Realm.io',
            'deployd',
            'Ruby on Rails',
            'LAMP',
            'Firebase',
            'Heroku',
            'AWS',
            'Python',
            'Shell',
            'PHP',
            'Hyperledger',
            'Go',
          ]"
          :techno="[]"
        />
        <techno-card
          :image="require('../assets/Mobile.png')"
          :name="'Mobile'"
          :description="['iOS', 'Android']"
          :techno="['Java', 'Kotlin', 'ObjectiveC', 'Swift', 'React Native']"
        />
        <techno-card
          :image="require('../assets/vr.png')"
          :name="'VR'"
          :description="['GearVR', 'Oculus Rift', 'Oculus GO', 'Cardboard']"
          :techno="[]"
        />
        <techno-card
          :image="require('../assets/ar.png')"
          :name="'AR'"
          :description="[
            'AR Kit',
            'AR Core',
            'Vuforia/Unity',
            'Structure/Bridge',
            'Geofencing',
            'Geolocalisation',
          ]"
          :techno="[]"
        />
        <techno-card
          :image="require('../assets/games.png')"
          :name="'GAMES'"
          :description="['iOS', 'Android', 'Nintendo Switch']"
          :techno="['Unity3d', 'Cocos2d', 'In-House Engines']"
        />
      </div>
    </b-container>

    <div class="spacer" />
    <neko-separator :separator_text="$t('WHO_WE_WORK_WITH')" />
    <partner-banner />

    <div class="spacer" />
  </div>
</template>

<script>
import NekoSeparator from "@/components/NekoSeparator.vue";
import TechnoCards from "@/components/TechnoCards.vue";
import PartnerBanner from "@/components/PartnerBanner.vue";

export default {
  name: "Services",
  components: {
    "techno-card": TechnoCards,
    "neko-separator": NekoSeparator,
    "partner-banner": PartnerBanner,
  },
  mounted() {
    this.track();
  },
  methods: {
    track() {
      this.$gtag.pageview({
        page_path: "/services",
      });
    },
  },
};
</script>

<style scoped>
.spacer {
  height: 80px;
}
#text {
  text-align: justify;
  padding-right: 100px;
  padding-left: 100px;
  color: #3c1c35;
}
p {
  margin-top: 30px;
  margin-bottom: 30px;
}
.my-grid-platforms {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 1em;
}

.white_bg {
  background-color: whitesmoke;
  padding: 30px;
  border-radius: 10px;
}
.white_bg p {
  margin-top: 0px;
  margin-bottom: 30px;
  white-space: pre-line;
}
.service_title {
  font-weight: 900;
}
.card-shadow {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.offer_title {
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 50px;
  color: #3c1c35;
}

ul {
  margin-left: 20px;
  color: #3c1c35;
  list-style-type: none;
  margin-right: 30px;
}
li {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.neko_icon_services {
  height: 100px;
  width: 100px;
}
.icon_image {
  margin-right: 20px;
}

@media only screen and (max-width: 996px) {
  .my-grid-platforms {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em 1em;
  }
}

/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (max-width: 768px) {
  #text {
    padding-right: 20px;
    padding-left: 20px;
  }
  p {
    text-align: left;
  }
  li {
    flex-wrap: wrap;
  }
  .my-grid-platforms {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em 1em;
  }

  .white_bg p {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .icon_image {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 376px) {
  .my-grid-platforms {
    display: inline;
  }
}
</style>
